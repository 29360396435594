import consumer from "./consumer"

$(function() {
  var $notif = $('#item-page-identifier')
  if($notif.length === 0) return

  consumer.subscriptions.create("SidekiqJobResultChannel", {
    received(data) {
      var target = '#'+data.jid;
      if ($(target).length ===0) return;

      var targetMessage = '#'+data.jid+'_message';
      var targetConfirm = '#'+data.jid+'_confirm';
      var targetErrorMessage = '#'+data.jid+'_error_message';
      $(target).css('width', data.current+'%')
      $(target).attr('aria-valuenow', data.current);
      $(target).text(data.current+'%');
      $(targetMessage).text(data.message);
      if (data.error_message != null) {
        $(targetErrorMessage).removeClass('d-none');
        $(targetErrorMessage).html(data.error_message);
      }
      

      if (parseInt(data.current) == 100) {
        $(targetConfirm).removeClass('d-none');
        $('.refresh-datatable').trigger('click');
      }
    }
  });
})