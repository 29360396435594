import consumer from "./consumer"

$(function() {
  var $notif = $('#device-notification-container');
  if($notif.length === 0) return;

  consumer.subscriptions.create("BridgeChannel", {
    connected() {
      console.log('connected to bridge');
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
  
    received(data) {
      console.log(data);
      if (data.result === undefined && data.message == undefined) return;

      toastr[data.result](data.message)
      $('.btn').prop('disabled', false);
      $('#warning-message').addClass('d-none');
    }
  });
})
