import consumer from "./consumer"

$(document).ready(function() {
  var administratorId = $('#current-administrator-id').data('current-administrator-id');
  if (administratorId != undefined) {
    // console.log('connecting administrator', administratorId);
    consumer.subscriptions.create(
      { 
        channel: "AdministratorSessionChannel",
        id: administratorId 
      }, {
        connected() {
          // console.log('connected!');
        },
        disconnected() {
          // console.log('disconected!');
        },
        received(data) {
          if (administratorId != data.except) {
            if (data.cmd == 'cmd_sign_out') {
              $.ajax({
                url: '/administrator/logout',
                type: "DELETE"
              }).done(function(){
                window.location.href = "/administrator/login";
              });
            }
          }
        }
      }
    );
  } else {
    // console.log('no administrator logged in!');
  }
});